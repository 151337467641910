import {
  IApplyData,
  ICompany,
  IJobTrend,
  ISearchAddress,
  Response,
} from '@/shared/types';
import axios from 'axios';
import camelcaseKeys from 'camelcase-keys';

const BASE_URL = process.env.NEXT_PUBLIC_BASE_URL;

export async function getApplyData() {
  const resp = await fetch(BASE_URL + '/api/jobs/apply-data');
  const json: IApplyData = await resp.json();
  return json;
}

export async function getAddress(params?: ISearchAddress) {
  const searchParam = new URLSearchParams({ scope: 'province', ...params });
  const url = BASE_URL + '/proxy/backoffice-svc/address?' + searchParam;
  const resp = await fetch(url);
  const json: string[] = await resp.json();
  return json;
}

export async function fetchJobTrends(numOfData: number) {
  const resp = await fetch(BASE_URL + '/api/jobs/trending?limit=' + numOfData);
  const json: IJobTrend[] = await resp.json();
  return json;
}

export async function fetchCompany() {
  const resp = await fetch(BASE_URL + '/api/company');
  const json: ICompany = await resp.json();
  return json;
}

export async function postCv(data: FormData) {
  const url = BASE_URL + '/proxy/backoffice-svc/cv';
  return axios.post(url, data, {
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  });
}
