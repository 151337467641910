import { TextHeading } from '@/components';
import clsx from 'clsx';
import type * as CSS from 'csstype';
import { ReactNode } from 'react';

type PageHeaderProps = {
  children: ReactNode;
  height?: string;
  bgUrl: string;
  className?: string;
};
const PageHeader = ({
  children,
  height,
  bgUrl,
  className,
}: PageHeaderProps) => {
  const wrapperStyles: CSS.Properties = {
    backgroundImage: `linear-gradient(0deg, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 0.5)), url('${bgUrl}')`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
  };

  return (
    <div style={wrapperStyles}>
      <div
        className={clsx(
          'container',
          'mx-auto',
          'px-2 lg:px-0',
          'py-10',
          height ?? 'h-[25rem] md:h-[30rem] lg:h-[35rem]',
          className
        )}
      >
        <div className="flex items-center h-full">{children}</div>
      </div>
    </div>
  );
};

type PageHeadingProps = {
  title: string;
  subTitle: string;
};
const PageHeading = ({ title, subTitle }: PageHeadingProps) => {
  return (
    <div className="text-white">
      <TextHeading
        as="h1"
        className="text-5xl font-bold leading-normal"
        alignment="top-left"
      >
        {title}
      </TextHeading>
      <span className="text-xl">{subTitle}</span>
    </div>
  );
};

type PageProps = {
  children: ReactNode;
};
const Page = ({ children }: PageProps) => {
  return <>{children}</>;
};

Page.displayName = 'Page';

export default Object.assign(Page, {
  Header: PageHeader,
  Heading: PageHeading,
});
