import { ApplicationLogo, BurgerMenu } from '@/components';
import Link from 'next/link';
import { ICompany } from '@/shared/types';
import TopHeader from './TopHeader';

type HeaderProps = {
  company: ICompany;
};
const Header = ({ company }: HeaderProps) => {
  return (
    <header>
      {/* begin: larger view port */}
      <div className="hidden lg:block">
        {/* begin: top header */}
        <TopHeader company={company} />
        {/* end: top header */}
        {/* begin: header content */}
        <nav className="bg-white">
          <div className="h-24 container mx-auto">
            <div className="flex justify-between items-center h-full">
              {/* begin: logo */}
              <ApplicationLogo className="w-40" />
              {/* end: logo */}

              {/* begin: menu list */}
              <div className="flex gap-4 xl:gap-6 font-medium">
                <Link href="/">หน้าแรก</Link>
                <Link href="/apply-job">ลงทะเบียนสมัครงาน</Link>
                <Link href="/jobs">ตำแหน่งงาน</Link>
                <Link href="/payroll">บริการรับทำเงินเดือน</Link>
                <Link href="/our-services">บริการสรรหาพนักงาน</Link>
                <Link href="/about-us">เกี่ยวกับบริษัท</Link>
                <Link href="/contact-us">ติดต่อเรา</Link>
              </div>
              {/* end: menu list */}
            </div>
          </div>
        </nav>
        {/* end: header content */}
      </div>
      {/* end: larger view port */}
      {/* begin: small view port */}
      <div className="block lg:hidden">
        <nav className="bg-primary text-white">
          <div className="container mx-auto h-[60px] px-2 lg:px-0 py-3">
            <div className="flex justify-between items-center">
              {/* begin: logo */}
              <ApplicationLogo className="w-20" />
              {/* end: logo */}
              {/* begin: hamburger menu */}
              <BurgerMenu company={company} />
              {/* end: hamburger menu */}
            </div>
          </div>
        </nav>
      </div>
      {/* end: small view port */}
    </header>
  );
};

export default Header;
