import { ErrorResponse, IAddress, IApplyData } from '@/shared/types';
import { zodResolver } from '@hookform/resolvers/zod';
import { AxiosError } from 'axios';
import camelcaseKeys from 'camelcase-keys';
import clsx from 'clsx';
import Link from 'next/link';
import { serialize } from 'object-to-formdata';
import { ChangeEvent, useState } from 'react';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import snakecaseKeys from 'snakecase-keys';
import { getAddress, postCv } from '../../../services';
import { addServerErrors } from '../../../shared/utils';
import ErrorMessage from '../ErrorMessage';
import { applySchema, TApplyJob } from './schema';

const SectionTitle = ({ title }: { title: string }) => (
  <div className="col-span-1 md:col-span-2">
    <h2 className="text-base font-medium">{title}</h2>
  </div>
);

type ApplyJobFormProps = {
  data: IApplyData;
  address: string[];
  id: string | null;
};
const ApplyJobForm = ({
  data,
  address: initialProvince,
  id,
}: ApplyJobFormProps) => {
  const {
    register,
    handleSubmit,
    reset,
    setError,
    getValues,
    formState: { isSubmitting, errors },
  } = useForm<TApplyJob>({
    resolver: zodResolver(applySchema),
  });
  const [address, setAddress] = useState<IAddress>({
    provinces: initialProvince,
    amphoes: undefined,
    tambons: undefined,
    zipCodes: undefined,
  });

  const resetAddress = () => {
    setAddress({
      provinces: initialProvince,
      amphoes: undefined,
      tambons: undefined,
      zipCodes: undefined,
    });
  };

  const onSubmit: SubmitHandler<TApplyJob> = async (data: TApplyJob) => {
    const formData = serialize(snakecaseKeys(data));

    // transform data
    formData.append('last_salary', '-');

    if (data.resumeFile[0]) {
      formData.append('resume_file', data.resumeFile[0]);
    }

    if (data.profilePhoto[0]) {
      formData.append('profile_photo', data.profilePhoto[0]);
    }

    return toast
      .promise(postCv(formData), {
        loading: 'กำลังส่งข้อมูล...',
        success: 'ส่งข้อความติดต่อสำเร็จ.',
        error: 'ไม่สำเร็จลองใหม่อีกครั้ง! หรือโทร (+66) 02-671-1150 ต่อ 24',
      })
      .then(() => {
        resetAddress();
        reset();
      })
      .catch((error: AxiosError<ErrorResponse<TApplyJob>>) => {
        if (error.response) {
          const result = error.response.data;
          if (result?.errors) {
            addServerErrors(camelcaseKeys(result.errors), setError);
          }
        } else if (error.request) {
          console.log(error.request);
        } else {
          console.log('Error', error.message);
        }
      });
  };

  const handleAddressChange = async (
    e: ChangeEvent<HTMLSelectElement>,
    currentScope: string
  ) => {
    const value = e.currentTarget.value;
    const ctxValues = getValues(['province', 'country', 'district']);

    // find scope: province(initial), amphoe, tambon(district), zipcode
    if (currentScope === 'province') {
      setAddress({
        provinces: initialProvince,
        amphoes: undefined,
        tambons: undefined,
        zipCodes: undefined,
      });
      reset({
        country: '',
        district: '',
        zipCode: '',
      });

      // next: scope: 'amphoe'
      setAddress((address) => ({
        ...address,
        amphoes: undefined,
      }));
      const amphoes = await getAddress({ scope: 'amphoe', province: value });
      setAddress((address) => ({
        ...address,
        amphoes,
      }));
    } else if (currentScope === 'amphoe') {
      setAddress((address) => ({
        ...address,
        tambons: undefined,
        zipCodes: undefined,
      }));
      reset({
        district: '',
        zipCode: '',
      });

      // next: scope: 'tambon'
      const tambons = await getAddress({
        scope: 'tambon',
        province: ctxValues[0],
        amphoe: value,
      });
      setAddress((address) => ({
        ...address,
        tambons,
      }));
    } else if (currentScope === 'district') {
      setAddress((address) => ({
        ...address,
        zipCodes: undefined,
      }));
      reset({
        zipCode: '',
      });

      // next: scope: 'zipcode'
      const zipCodes = await getAddress({
        scope: 'zipcode',
        province: ctxValues[0],
        amphoe: ctxValues[1],
        tambon: value,
      });
      setAddress((address) => ({
        ...address,
        zipCodes,
      }));
    }
  };

  const defaultJobId = () => {
    if (!id) return '';

    const { jobOptions } = data;
    const found = jobOptions.find((jo) => jo.id.toString() === id);
    return found ? id : '';
  };

  return (
    <form
      className="grid grid-cols-1 md:grid-cols-2 space-y-2 md:space-y-0 md:gap-2 mt-5 mb-24 sm:mb-0"
      onSubmit={handleSubmit(onSubmit)}
    >
      {/* begin: section position information */}
      <SectionTitle title="ข้อมูลตำแหน่งงาน" />
      <div>
        <label className="label">
          <span className="label-text">ตำแหน่งงาน*</span>
        </label>
        <select
          {...register('jobId')}
          className="select select-bordered bg-gray-light w-full"
          defaultValue={defaultJobId()}
        >
          <option value="" disabled>
            เลือกตำแหน่งงานที่ต้องการสมัคร
          </option>
          {data.jobOptions.map((option) => (
            <option
              key={option.id}
              value={option.id}
              disabled={option.id === 0}
            >
              {option.name} - {option.company_name}{' '}
              {option.is_highlight && '(ด่วน!)'}
            </option>
          ))}
        </select>
        <ErrorMessage field={errors.jobId} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">
            เงินเดือนที่คาดหวัง (บาท)/Expected Salary (baht)*
          </span>
        </label>
        <input
          {...register('expectSalary')}
          type="text"
          className="input input-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.expectSalary} />
      </div>
      {/* end: section position information */}
      {/* begin: section profile image */}
      <SectionTitle title="รูปภาพของฉัน" />
      <div className="md:col-span-2">
        <input
          {...register('profilePhoto')}
          type="file"
          accept="image/jpeg, image/jpg, image/png"
          className="file-input file-input-bordered bg-gray-light w-full"
        />
        <span className="text-base-300 text-sm">
          *รองรับไฟล์นามสกุล jpg/jpeg และ png เท่านั้น
        </span>
        <ErrorMessage field={errors.profilePhoto} />
      </div>
      {/* end: section profile image  */}
      {/* begin: section personal information */}
      <SectionTitle title="ข้อมูลส่วนตัว" />
      <div>
        <label className="label">
          <span className="label-text">ชื่อ / Name*</span>
        </label>
        <input
          {...register('firstName')}
          type="text"
          className="input input-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.firstName} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">นามสกุล / Last Name*</span>
        </label>
        <input
          {...register('lastName')}
          type="text"
          className="input input-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.lastName} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">วัน/เดือน/ปีเกิด / Date of birth*</span>
        </label>
        <input
          {...register('birthDate')}
          type="date"
          className="input input-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.birthDate} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">เพศ / Gender*</span>
        </label>
        <select
          {...register('gender')}
          defaultValue=""
          className="select select-bordered bg-gray-light w-full"
        >
          <option value="" disabled>
            เลือกเพศ
          </option>
          <option value="male">ชาย</option>
          <option value="female">หญิง</option>
        </select>
        <ErrorMessage field={errors.gender} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">สถานภาพ / Marital Status*</span>
        </label>
        <select
          {...register('maritalStatus')}
          className="select select-bordered bg-gray-light w-full"
          defaultValue=""
        >
          <option value="" disabled>
            เลือกสถานภาพ
          </option>
          {data.maritalStatus.map((ms, idx) => (
            <option value={ms.textEn} key={idx}>
              {ms.textTh}
            </option>
          ))}
        </select>
        <ErrorMessage field={errors.maritalStatus} />
      </div>
      <div className="flex gap-2">
        <div className="flex-1">
          <label className="label">
            <span className="label-text">น้ำหนัก / Weight (กก. Kg.)*</span>
          </label>
          <input
            {...register('weight')}
            type="number"
            className="input input-bordered bg-gray-light w-full"
            min={0}
          />
          <ErrorMessage field={errors.weight} />
        </div>
        <div className="flex-1">
          <label className="label">
            <span className="label-text">ส่วนสูง / Height (ซม. Cm.)*</span>
          </label>
          <input
            {...register('height')}
            type="number"
            className="input input-bordered bg-gray-light w-full"
            min={0}
          />
          <ErrorMessage field={errors.height} />
        </div>
      </div>
      <div>
        <label className="label">
          <span className="label-text">ศาสนา / Religion*</span>
        </label>
        <select
          {...register('religion')}
          className="select select-bordered bg-gray-light w-full"
          defaultValue=""
        >
          <option value="" disabled>
            เลือกศาสนา
          </option>
          {data.religions.map((r, idx) => (
            <option value={r.textEn} key={idx}>
              {r.textTh}
            </option>
          ))}
        </select>
        <ErrorMessage field={errors.religion} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">
            ประเภทใบขับขี่ / Driving license (หากมี)
          </span>
        </label>
        <input
          {...register('drivingLicense')}
          type="text"
          placeholder="ใบขับขี่รถยนต์ส่วนบุคคล, ใบขับขี่รถจักรยานยนต์ส่วนบุคคล หรือ ใบอนุญาตขับรถระหว่างประเทศ"
          className="input input-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.drivingLicense} />
      </div>
      <div className="col-span-1 md:col-span-2">
        <label className="label">
          <span className="label-text">ที่อยู่ปัจจุบัน / Address*</span>
        </label>
        <input
          {...register('address')}
          type="text"
          className="input input-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.address} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">จังหวัด / Province*</span>
        </label>
        <select
          {...register('province', {
            onChange: (e) => handleAddressChange(e, 'province'),
          })}
          className="select select-bordered bg-gray-light w-full"
          defaultValue=""
        >
          <option value="" disabled>
            เลือกจังหวัด
          </option>
          {address.provinces?.map((province, idx) => (
            <option value={province} key={idx}>
              {province}
            </option>
          ))}
        </select>
        <ErrorMessage field={errors.province} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">เขต/อำเภอ / Country*</span>
        </label>
        <select
          {...register('country', {
            onChange: (e) => handleAddressChange(e, 'amphoe'),
            disabled: !address.amphoes,
          })}
          className="select select-bordered bg-gray-light disabled:bg-gray-light disabled:border-none disabled:cursor-not-allowed w-full"
          defaultValue=""
        >
          <option value="" disabled>
            เลือกเขต/อำเภอ
          </option>
          {address.amphoes?.map((amphoe, idx) => (
            <option value={amphoe} key={idx}>
              {amphoe}
            </option>
          ))}
        </select>
        <ErrorMessage field={errors.country} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">แขวง/ตำบล / District*</span>
        </label>
        <select
          {...register('district', {
            onChange: (e) => handleAddressChange(e, 'district'),
            disabled: !address.tambons,
          })}
          className="select select-bordered bg-gray-light disabled:bg-gray-light disabled:border-none disabled:cursor-not-allowed w-full"
          defaultValue=""
        >
          <option value="" disabled>
            เลือกแขวง/ตำบล
          </option>
          {address.tambons?.map((tambon, idx) => (
            <option value={tambon} key={idx}>
              {tambon}
            </option>
          ))}
        </select>
        <ErrorMessage field={errors.district} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">รหัสไปรษณีย์ / Zip Code*</span>
        </label>
        <select
          {...register('zipCode', {
            disabled: !address.zipCodes,
          })}
          className="select select-bordered bg-gray-light disabled:bg-gray-light disabled:border-none disabled:cursor-not-allowed w-full"
          defaultValue=""
        >
          <option value="" disabled>
            เลือกรหัสไปรษณีย์
          </option>
          {address.zipCodes?.map((zipCode, idx) => (
            <option value={zipCode} key={idx}>
              {zipCode}
            </option>
          ))}
        </select>
        <ErrorMessage field={errors.zipCode} />
      </div>
      {/* end: section personal information */}
      {/* begin: section contact */}
      <SectionTitle title="การติดต่อ" />
      <div>
        <label className="label">
          <span className="label-text">เบอร์โทรศัพท์ / Tel.*</span>
        </label>
        <input
          {...register('tel')}
          type="text"
          className="input input-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.tel} />
      </div>
      <div>
        <label className="label">
          <span className="label-text">อีเมล / Email*</span>
        </label>
        <input
          {...register('email')}
          type="text"
          className="input select-bordered bg-gray-light w-full"
        />
        <ErrorMessage field={errors.email} />
      </div>
      {/* end: section contact */}
      {/* begin: section attachment files */}
      <SectionTitle title="แนบไฟล์เรซูมเม่ (ถ้ามี)" />
      <div className="col-span-1 md:col-span-2">
        <input
          {...register('resumeFile')}
          type="file"
          accept="application/pdf"
          className="file-input bg-gray-light w-full"
        />
        <span className="text-base-300 text-sm">
          *รองรับไฟล์นามสกุล PDF เท่านั้น
        </span>
        <ErrorMessage field={errors.resumeFile} />
      </div>
      {/* end: section attachment files */}
      {/* begin: section consents */}
      <div className="col-span-1 md:col-span-2">
        <div className="form-control">
          <label className="label justify-start space-x-2 cursor-pointer">
            <input
              {...register('isAcceptedPdpa')}
              type="checkbox"
              className="checkbox checkbox-sm"
            />
            <span className="label-text">
              ฉันยอมรับ{' '}
              <Link href="/terms-and-conditions">
                <a
                  className="text-primary underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  เงื่อนไขข้อตกลงสำหรับการให้บริการ
                </a>
              </Link>
              และ
              <Link href="/privacy-policy">
                <a
                  className="text-primary underline"
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  นโยบายคุ้มครองข้อมูลส่วนบุคคล
                </a>
              </Link>
            </span>
          </label>
        </div>
      </div>
      {/* end: section consents */}
      <div className="col-span-1 md:col-span-2">
        <div className="text-center">
          <button
            type="submit"
            className={clsx('btn btn-primary', { loading: isSubmitting })}
            disabled={isSubmitting}
          >
            ส่งใบสมัคร
          </button>
        </div>
      </div>
    </form>
  );
};

export default ApplyJobForm;
