import Image from 'next/image';
import React from 'react';
import AliceCarousel from 'react-alice-carousel';

const carouselItems = [
  '/images/ctl/CTL-Page-Activity-1.jpg',
  '/images/ctl/CTL-Page-Activity-2.jpg',
  '/images/ctl/CTL-Page-Activity-3.jpg',
  '/images/ctl/CTL-Page-Activity-4.jpg',
  '/images/ctl/CTL-Page-Activity-5.jpg',
  '/images/ctl/CTL-Page-Activity-6.jpg',
];

const CarouselSlide = () => {
  const handleDragStart = (e: React.DragEvent<HTMLImageElement>) =>
    e.preventDefault();

  return (
    <div className="container mx-auto px-2 py-10 lg:py-14 h-full">
      <AliceCarousel
        mouseTracking
        items={carouselItems.map((p) => (
          <Image
            src={p}
            alt={p.split('/').pop() ?? 'AliceCarouselItem' + Math.random()}
            width={500}
            height={376}
            key={p}
            onDragStart={handleDragStart}
          />
        ))}
        autoPlayInterval={3000}
        responsive={{
          0: { items: 1 },
          568: { items: 2 },
          1024: { items: 4 },
        }}
        controlsStrategy="alternate"
        autoPlay
        infinite
        disableButtonsControls
      />
    </div>
  );
};

export default CarouselSlide;
