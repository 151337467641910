import ctlLogo from '@/public/images/logos/ctl-logo.png';
import clsx from 'clsx';
import Image from 'next/image';

type CrystalLifeLogoProps = {
  className: string;
};
const CrystalLifeLogo = ({ className }: Partial<CrystalLifeLogoProps>) => {
  return (
    <a href="https://www.mycrystallife.com/" className={clsx(className)}>
      <Image src={ctlLogo} alt="crl-logo" layout="responsive" />
    </a>
  );
};

export default CrystalLifeLogo;
