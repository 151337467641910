import { ApplicationLogo, TextHeading } from '@/components';
import phrLineQRCode from '@/public/images/QR CODE PHR.jpg';
import Image from 'next/image';
import Link from 'next/link';
import { ReactNode } from 'react';
import { ICompany } from '@/shared/types';

const Legal = () => {
  return (
    <div className="bg-base-200 text-white">
      <div className="container mx-auto px-2 py-7">
        <div className="flex flex-col lg:flex-row justify-between items-center gap-2">
          <span className="text-center max-w-sm md:max-w-full">
            Copyright © 2022 Perfect Hunter Recruitment Co., Ltd. All Rights
            Reserved.
          </span>
          <div>
            <ul className="flex flex-col md:flex-row justify-end items-center gap-2 md:gap-4">
              <li>
                <Link href="/terms-and-conditions">ข้อกำหนดและเงื่อนไข</Link>
              </li>
              <li>
                <Link href="/privacy-policy">นโยบายความเป็นส่วนตัว</Link>
              </li>
              <li>
                <Link href="/cookies-policy">นโยบายการใช้งานคุกกี้</Link>
              </li>
            </ul>
          </div>
        </div>
      </div>
    </div>
  );
};

const Quote = () => {
  return (
    <div className="bg-primary">
      <div className=" mx-auto py-12 text-center">
        <p className='text-xl lg:text-2xl text-white before:content-["\""] before:text-black after:content-["\""] after:text-black'>
          ได้รับความไว้วางใจที่ได้ใช้บริการกับเรา และ
          <br className="block lg:hidden" />
          บริษัทในเครือของเราจากหลากหลายธุรกิจชั้นนำมากมาย
          <br className="hidden sm:block" />
          การเติบโตที่ก้าวกระโดด จึงมั่นใจได้ในการบริการที่มีคุณภาพของเรา
        </p>
      </div>
    </div>
  );
};

interface ContactItem {
  icon: string;
  iconColor?: 'primary' | 'white';
  title: string;
  children: ReactNode;
}
const ContactItem = ({
  children,
  icon,
  iconColor = 'primary',
  title,
}: ContactItem) => {
  const realIconColor = `text-${iconColor}`;
  return (
    <div className="flex items-center text-sm">
      <i className={`fi ${icon} h-5 w-5 text-lg ${realIconColor} mr-5`} />
      <span className="font-bold mr-1">{title}:</span>
      {children}
    </div>
  );
};

const MainFooter = ({ company }: { company: ICompany }) => {
  return (
    <footer className="bg-base-300 text-sm text-white">
      <div className="container mx-auto px-2 py-20 lg:py-14 h-full">
        <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-4 gap-6">
          {/* begin: company contact */}
          <div className="flex flex-col gap-5">
            <ApplicationLogo className="w-24" />
            <h3 className="font-bold text-base">{process.env.COMPANY_NAME}</h3>
            <span>{company.address}</span>
            <ContactItem icon="fi-rr-phone-office" title="สำนักงาน">
              <a href={`tel:${company.phone}`}>
                {company.phone}{' '}
                {company.phone_ext ? `ต่อ ${company.phone_ext}` : null}
              </a>
            </ContactItem>
            <ContactItem icon="fi-rr-phone-call" title="โทรศัพท์มือถือ">
              <a href={`tel:${company.mobile_phone}`}>{company.mobile_phone}</a>
            </ContactItem>
            <ContactItem icon="fi-brands-facebook" title="Facebook">
              <a href={process.env.socialFbUrl}>Perfect Hunter Recruitment</a>
            </ContactItem>
            <ContactItem icon="fi-rr-envelope" title="อีเมล">
              <a href={`mailto:${company.email}`}>{company.email}</a>
            </ContactItem>
          </div>
          {/* end: company contact */}
          {/* begin: social network */}
          <div className="flex flex-col gap-5">
            <TextHeading as="h3" className="text-base mb-5">
              Social Networks
            </TextHeading>
            <ContactItem
              icon="fi fi-brands-line"
              title="Line"
              iconColor="white"
            >
              <a href={process.env.socialLineUrl} className="underline">
                เพิ่มเพื่อนที่นี่
              </a>
            </ContactItem>
            <ContactItem
              icon="fi fi-brands-tik-tok"
              title="Tiktok"
              iconColor="white"
            >
              <a href={process.env.socialTiktokUrl} className="underline">
                @perfect.jobs
              </a>
            </ContactItem>
            <ContactItem
              icon="fi fi-brands-facebook"
              title="Facebook"
              iconColor="white"
            >
              <a href={process.env.socialFbUrl} className="underline">
                Perfect Hunter Recruitment
              </a>
            </ContactItem>
            <div className="text-center">
              <div className="md:w-44 lg:mx-auto">
                <Image src={phrLineQRCode} alt="add friend @perfect_hunter" />
              </div>
            </div>
          </div>
          {/* end: social network */}
          {/* begin: quick search */}
          <div className="flex flex-col gap-5">
            <TextHeading as="h3" className="text-base mb-5">
              ค้นงานจากหมวดหมู่งาน
            </TextHeading>
            <ul className="list-none space-y-2.5">
              <li>
                <Link href="/jobs">งานกฏหมาย</Link>
              </li>
              <li>
                <Link href="/jobs">งานกระจายสินค้า-ขนส่ง</Link>
              </li>
              <li>
                <Link href="/jobs">งานการตลาด</Link>
              </li>
              <li>
                <Link href="/jobs">งานการบัญชี</Link>
              </li>
              <li>
                <Link href="/jobs">งานการเงิน</Link>
              </li>
            </ul>
          </div>
          {/* end: quick search */}
          {/* begin: customer quick link */}
          <div className="flex flex-col gap-5">
            <TextHeading as="h3" className="text-base mb-5">
              สำหรับผู้สมัครงาน
            </TextHeading>
            <Link href="/jobs">
              <a className="btn btn-primary w-fit">ค้นหางานที่ใช่</a>
            </Link>
            <Link href="/apply-job">
              <a className="btn btn-primary w-fit">ลงทะเบียนสมัครงานกับเรา</a>
            </Link>
            <TextHeading as="h3" className="text-base mb-5">
              สำหรับบริษัท
            </TextHeading>
            <Link href="/contact-us">
              <a className="btn btn-primary w-fit">ติดต่อเรา</a>
            </Link>
          </div>
          {/* end: customer quick link */}
        </div>
      </div>
    </footer>
  );
};

type FooterProps = {
  company: ICompany;
};
const Footer = ({ company }: FooterProps) => {
  return (
    <>
      {/* begin: brand quote */}
      <Quote />
      {/* end: brand quote */}

      {/* begin: footer */}
      <MainFooter company={company} />
      {/* end: footer */}

      {/* begin: company legal */}
      <Legal />
      {/* end: company legal */}
    </>
  );
};

export default Footer;
