import { z } from 'zod';

export const applySchema = z.object({
  jobId: z.string().min(1, 'โปรดเลือกตำแหน่งงาน'),
  expectSalary: z.string().min(1, 'กรุณาระบุเงินเดือนที่คาดหวัง'),
  firstName: z.string().min(1, 'กรุณาระบุชื่อของคุณ'),
  lastName: z.string().min(1, 'กรุณาระบุนามสกุลของคุณ'),
  birthDate: z.string().min(1, 'กรุณาระบุวันเดือนปีเกิด'),
  gender: z.string().min(1, 'กรุณาระบุเพศ'),
  maritalStatus: z.string().min(1, 'โปรดระบุสถานภาพ'),
  weight: z.string().min(1, 'กรุณาระบุน้ำหนัก'),
  height: z.string().min(1, 'กรุณาระบุส่วนสูง'),
  religion: z.string().min(1, 'โปรดระบุศาสนา'),
  drivingLicense: z.string(),
  address: z.string().min(1, 'กรุณาระบุที่อยู่'),
  province: z.string().min(1, 'โปรดเลือกจังหวัด'),
  country: z
    .string({ required_error: 'โปรดเลือกเขต/อำเภอด' })
    .min(1, 'โปรดเลือกเขต/อำเภอ'),
  district: z
    .string({ required_error: 'โปรดเลือกแขวงตำบล' })
    .min(1, 'โปรดเลือกแขวงตำบล'),
  zipCode: z
    .string({ required_error: 'โปรดเลือกรหัสไปรษณีย์' })
    .min(1, 'โปรดเลือกรหัสไปรษณีย์'),
  tel: z.string().min(1, 'กรุณาระบุเบอร์โทรศัพท์'),
  email: z.string().email('กรุณาระบุอีเมลที่ถูกต้อง'),
  isAcceptedPdpa: z.boolean(),
  resumeFile: z.any(),
  profilePhoto: z.any(),
});

export type TApplyJob = z.infer<typeof applySchema>;
