import clsx from 'clsx';
import { ElementType, HTMLAttributes } from 'react';

export interface TextHeadingProps extends HTMLAttributes<HTMLOrSVGElement> {
  as?: ElementType;
  alignment?: 'bottom-left' | 'top-left' | 'top-center';
  topLineWidth?: number | string;
}
const TextHeading = ({
  children,
  className,
  as: Tag = 'h1',
  alignment = 'bottom-left',
}: TextHeadingProps) => {
  // TODO: custom space between line and text and calculate height
  const alignClass = `text-${alignment.split('-')[1]}`;
  const position = {
    'bottom-left': 'before:inset-x-0 before:-bottom-3',
    'top-left': 'before:inset-x-0 before:-top-3',
    'top-center': 'before:inset-x-0 before:-top-3 before:mx-auto',
  };
  const strTag = Tag as string;
  const beforeWidth = {
    h1: 'before:w-16',
    h2: 'before:w-14',
    other: 'before:w-12',
  };
  const beforeWidthClass = ['h1', 'h2'].includes(strTag)
    ? beforeWidth[strTag as keyof typeof beforeWidth]
    : beforeWidth.other;

  return (
    <div className={clsx(alignClass)}>
      <Tag
        className={clsx(
          'before:block before:absolute before:h-1',
          beforeWidthClass,
          'before:bg-primary relative inline-block font-bold',
          position[alignment],
          className
        )}
      >
        {children}
      </Tag>
    </div>
  );
};

export default TextHeading;
