import { ParsedUrlQuery } from 'querystring';
import { Key } from 'swr';

/**
 * Calculate Total Pages
 * @param total - total elements of array
 * @param size - size of element per page
 * @returns
 */
export function calPages(total: number, size: number) {
  return Math.ceil(total / size);
}

/**
 * Calculate Pagination Skip Element
 * @param page - current page number
 * @param size - page size
 * @returns
 */
export function calSkip(page: number, size: number) {
  return (page - 1) * size;
}

/**
 * Generate paginate url
 * @param key - endpoint prefix
 * @param pageIndex - current page number
 * @param limit - limit of element
 * @returns
 */
export function getPaginateKey(
  key: Key,
  pageIndex: number,
  limit: number,
  options?: ParsedUrlQuery
) {
  const tempOption = options;
  if (tempOption) {
    Object.keys(tempOption).forEach((key) => {
      if (tempOption[key] === undefined) {
        delete tempOption[key];
      }
    });
  }

  const searchParams = new URLSearchParams({
    page: `${pageIndex + 1}`,
    per_page: `${limit}`,
    ...tempOption,
  });
  return `${key}?${searchParams.toString()}`;
}

export function addServerErrors<T>(
  errors: { [P in keyof T]?: string[] },
  setError: (
    fieldName: keyof T,
    error: { type: string; message: string }
  ) => void
) {
  return Object.keys(errors).forEach((key) => {
    setError(key as keyof T, {
      type: 'server',
      message: errors[key as keyof T]!.join('. '),
    });
  });
}
