import { ErrorMessage, TextHeading } from '@/components';
import { zodResolver } from '@hookform/resolvers/zod';
import axios from 'axios';
import { useRouter } from 'next/router';
import { SubmitHandler, useForm } from 'react-hook-form';
import toast from 'react-hot-toast';
import snakecaseKeys from 'snakecase-keys';
import { z } from 'zod';

const SEND_CONTACT_API = '/proxy/backoffice-svc/contact/send';

const contactSchema = z.object({
  subject: z.string().min(1, 'โปรดเลือกหัวข้อเรื่องที่ต้องการติดต่อ'),
  name: z.string().min(1, 'โปรดป้อนชื่อผู้ติดต่อ'),
  email: z.string().email('โปรดป้อนอีเมลที่ถูกต้อง'),
  phoneNumber: z.string().min(9, 'โปรดป้อนเบอร์โทรศัพท์ที่ถูกต้อง').optional(),
  message: z.string().min(1, 'โปรดป้อนข้อความ'),
});

export type TContact = z.infer<typeof contactSchema>;

const ContactForm = () => {
  const { query } = useRouter();
  const {
    register,
    handleSubmit,
    reset,
    formState: { errors, isValid },
  } = useForm<TContact>({
    mode: 'onChange',
    resolver: zodResolver(contactSchema),
  });

  const onSubmit: SubmitHandler<TContact> = async (data) => {
    const save = axios
      .post(SEND_CONTACT_API, snakecaseKeys(data))
      .then(() => reset());

    toast.promise(save, {
      loading: 'กำลังส่งข้อมูล...',
      success: 'ส่งข้อความติดต่อสำเร็จ.',
      error: 'ไม่สำเร็จลองใหม่อีกครั้ง! หรือโทร (+66) 02-671-1150 ต่อ 24',
    });
  };

  return (
    <div id="form" className="px-12 py-16 text-black">
      <TextHeading
        as="h2"
        alignment="top-left"
        className="text-2xl leading-loose font-bold text-black mb-10"
      >
        Contact us / ติดต่อเรา
      </TextHeading>

      <form
        onSubmit={handleSubmit(onSubmit)}
        className="grid grid-cols-1 lg:grid-cols-2 gap-6"
      >
        <div>
          <select
            className="select w-full font-normal"
            defaultValue={query.subject ? query.subject : ''}
            {...register('subject')}
          >
            <option value="" disabled>
              เลือกเรื่องที่ต้องการติดต่อ
            </option>

            <option>ฉันสนใจบริการ [บริษัท]</option>
            <option>ฉันสนใจบริการ [ผู้สมัครงาน]</option>
            <option>สอบถามรายละเอียดของตำแหน่งงาน</option>
            <option>ฉันสนใจใช้บริการทำเงินเดือน Payroll</option>
            <option>เรื่องอื่น ๆ</option>
          </select>
          <ErrorMessage field={errors.subject} />
        </div>

        <div>
          <input
            type="text"
            placeholder="ชื่อ"
            className="input w-full"
            {...register('name', {})}
          />
          <ErrorMessage field={errors.name} />
        </div>

        <div>
          <input
            type="email"
            placeholder="อีเมล"
            className="input w-full"
            {...register('email', {})}
          />
          <ErrorMessage field={errors.email} />
        </div>

        <div>
          <input
            type="text"
            placeholder="เบอร์โทรศัพท์"
            className="input w-full"
            {...register('phoneNumber', {})}
          />
          <ErrorMessage field={errors.phoneNumber} />
        </div>

        <div className="lg:col-span-2">
          <textarea
            className="textarea w-full"
            placeholder="ข้อความ"
            rows={5}
            {...register('message')}
          ></textarea>
          <ErrorMessage field={errors.message} />
        </div>

        <div>
          <button type="submit" className="btn btn-primary" disabled={!isValid}>
            ส่งข้อมูล
          </button>
        </div>
      </form>
    </div>
  );
};
export default ContactForm;
