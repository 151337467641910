import { IJobTrend } from '@/shared/types';
import Link from 'next/link';

interface JobTrendCardProps {
  data: IJobTrend;
  isLast?: boolean;
}
const JobTrendCard = ({ data, isLast = false }: JobTrendCardProps) => {
  return (
    <Link href={`/jobs/${data.id}`}>
      <div
        className={`group hover:cursor-pointer bg-white border border-gray-200 ${
          isLast ? `border-b-1` : `border-b-0`
        }`}
      >
        <div className="flex flex-col gap-2.5 p-7">
          <span className="text-gray-500">{data.company_name}</span>
          <a className="font-medium group-hover:text-primary">{data.name}</a>
          <div className="flex">
            <i className="fi fi-rr-briefcase text-gray-500 text-xl w-6 h-6 mr-2.5" />
            {data.job_type_name}
          </div>
          <div className="flex">
            <i className="fi fi-rr-marker text-gray-500 text-xl w-6 h-6 mr-2.5" />
            {data.location}
          </div>
          <div className="flex">
            <i className="fi fi-rr-money-check-edit text-gray-500 text-xl w-6 h-6 mr-2.5" />
            {data.salary}
          </div>
        </div>
      </div>
    </Link>
  );
};

export default JobTrendCard;
