import { ReactNode } from 'react';
import { ICompany } from '@/shared/types';

type ItemProps = {
  icon?: string;
  children: ReactNode;
};
const Item = (props: ItemProps) => {
  return (
    <div className="px-3 xl:px-5 py-3 first:pl-0 last:pr-0">
      <div className="flex items-center">
        {props.icon && (
          <i className={`fi ${props.icon} h-5 w-5 text-lg mr-2.5`}></i>
        )}
        {props.children}
      </div>
    </div>
  );
};

type TopHeaderProps = {
  company: ICompany;
};
const TopHeader = ({ company }: TopHeaderProps) => {
  const itemList: ItemProps[] = [
    { children: company.email, icon: 'fi-rr-envelope' },
    { children: company.mobile_phone, icon: 'fi-rr-phone-call' },
    {
      children: company.address,
      icon: 'fi-rr-marker',
    },
  ];

  return (
    <div className="bg-primary text-white">
      <div className="container mx-auto">
        <div className="flex justify-between items-center">
          {/* begin: top header - left content */}
          <div className="flex divide-x divide-solid divide-white/20">
            <Item icon="fi-rr-envelope">
              <a className="text-sm" href={`mailto:${company.email}`}>
                {company.email}
              </a>
            </Item>
            <Item icon="fi-rr-phone-call">
              <a className="text-sm" href={`tel:${company.mobile_phone}`}>
                {company.mobile_phone}
              </a>
            </Item>
            <Item icon="fi-rr-marker">
              <span className="text-sm">{company.address}</span>
            </Item>
          </div>
          {/* end: top header - left content */}

          {/* begin: top header - right content */}
          <div className="flex divide-x divide-solid divide-white/20">
            <Item icon="fi-rr-time-oclock">
              <span className="text-sm">จันทร์ - ศุกร์ / 08.30 - 17.30</span>
            </Item>
            {/* begin: social link */}
            <Item>
              <a
                href={process.env.socialFbUrl}
                className="mr-2"
                aria-label="perfect hunter facebook page"
              >
                <i className="block fi fi-brands-facebook h-5 w-5 text-lg text-white"></i>
              </a>
              <a
                href={process.env.socialLineUrl}
                className="mr-2"
                aria-label="perfect hunter line account"
              >
                <i className="block fi fi-brands-line h-5 w-5 text-lg text-white"></i>
              </a>
              <a
                href={process.env.socialTiktokUrl}
                aria-label="perfect hunter tiktok account"
              >
                <i className="block fi fi-brands-tik-tok h-5 w-5 text-lg text-white"></i>
              </a>
            </Item>
            {/* end: social link */}
          </div>
          {/* end: top header - right content */}
        </div>
      </div>
    </div>
  );
};

export default TopHeader;
