/* eslint-disable @next/next/no-html-link-for-pages */
import clsx from 'clsx';
import Link from 'next/link';
import { ReactNode } from 'react';
import { slide as Menu } from 'react-burger-menu';
import { ICompany } from '../../shared/types';
import styles from './BurgerMenu.module.css';

type ContactItemProps = {
  icon: string;
  iconClasses?: string;
  title: string;
  titleClasses?: string;
  subTitleClasses?: string;
  element: ReactNode;
};
export const ContactItem = ({
  icon,
  title,
  iconClasses = 'text-white',
  titleClasses: titleFontSize = 'text-base',
  subTitleClasses: subTitleFontSize = 'font-normal',
  element,
}: ContactItemProps) => {
  return (
    <div className="flex items-center justify-start gap-5">
      <i
        className={clsx('fi', icon, 'h-9', 'w-9', 'text-[36px]', iconClasses)}
      ></i>
      <div>
        <p className={clsx(titleFontSize, 'font-bold')}>{title}</p>
        {typeof element == 'string' ? (
          <span className={subTitleFontSize}>{element}</span>
        ) : (
          element
        )}
      </div>
    </div>
  );
};

type BurgerMenuProps = {
  company: ICompany;
};
export const BurgerMenu = ({ company }: BurgerMenuProps) => {
  return (
    <Menu
      right
      className={styles.bmMenuWrap}
      burgerButtonClassName={styles.bmBurgerButton}
      burgerBarClassName={styles.bmBurgerBars}
      crossButtonClassName={styles.bmCrossButton}
      crossClassName={styles.bmCross}
      menuClassName={styles.bmMenu}
      morphShapeClassName={styles.bmMorphShape}
      itemListClassName={styles.bmItemList}
      itemClassName={styles.bmItem}
      overlayClassName={styles.bmOverlay}
      customBurgerIcon={<i className={styles.burgerMenuIcon}></i>}
      customCrossIcon={<i className="block fi fi-rr-cross h-5 w-5 text-xl"></i>}
    >
      {/* begin: page menu */}
      <a href="/">หน้าแรก</a>
      <a href="/apply-job">ลงทะเบียนสมัครงาน</a>
      <a href="/jobs">ตำแหน่งงาน</a>
      <a href="/payroll">บริการรับทำเงินเดือน</a>
      <a href="/our-services">บริการสรรหาพนักงาน</a>
      <a href="/about-us">เกี่ยวกับบริษัท</a>
      <a href="/contact-us">ติดต่อเรา</a>
      {/* end: page menu */}

      {/* begin: group of contact item */}
      <div>
        <div className="grid grid-cols-1 gap-5 mt-10">
          <ContactItem
            icon="fi-rr-map-marker"
            title="ที่อยู่"
            element={company.address}
          />
          <ContactItem
            icon="fi-rr-phone-call"
            title="เบอร์ติดต่อ"
            element={
              <>
                <a className="block" href={`tel:${company.phone}`}>
                  สำนักงาน: {company.phone}{' '}
                  {company.phone_ext ? `ต่อ ${company.phone_ext}` : null}
                </a>
                <a className="block" href={`tel:${company.mobile_phone}`}>
                  มือถือ: {company.mobile_phone}
                </a>
              </>
            }
          />
          <ContactItem
            icon="fi-brands-facebook"
            title="Facebook"
            element={
              <a href={process.env.socialFbUrl}>Perfect Hunter Recruitment</a>
            }
          />
          <ContactItem
            icon="fi-brands-line"
            title="LINE@"
            element={<a href={process.env.socialLineUrl}>Line Official</a>}
          />
          <ContactItem
            icon="fi-brands-tik-tok"
            title="Tiktok"
            element={<a href={process.env.socialTiktokUrl}>@perfect.jobs</a>}
          />
          <ContactItem
            icon="fi-rr-envelope"
            title="อีเมล"
            element={company.email}
          />
        </div>
      </div>
      {/* end: group of contact item */}
    </Menu>
  );
};
