import { JobTrendCard, Spinner } from '@/components';
import fetcher from '@/lib/fetcher';
import { IJobTrend } from '@/shared/types';
import useSWR from 'swr';

interface ItemsProps {
  data: IJobTrend[] | undefined;
  error: any;
}
const Items = ({ data, error }: ItemsProps) => {
  if (error) return <div>failed to load</div>;
  if (!data) return <Spinner />;

  return (
    <div className="flex flex-col">
      {data.map((t, i) => (
        <JobTrendCard data={t} isLast={i + 1 === data.length} key={t.id} />
      ))}
    </div>
  );
};

const JobSidebar = () => {
  const { data, error } = useSWR<IJobTrend[]>(
    '/api/jobs/trending?limit=5',
    fetcher
  );

  return (
    <>
      <h2 className="text-primary text-base font-medium mb-5">
        ตำแหน่งงานที่น่าสนใจ
      </h2>
      <Items data={data} error={error} />
    </>
  );
};

export default JobSidebar;
