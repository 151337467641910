import type { FieldError, FieldErrorsImpl, Merge } from 'react-hook-form';

const ErrorMessage = ({
  field,
}: {
  field: FieldError | Merge<FieldError, FieldErrorsImpl<any>> | undefined;
}) => {
  if (!field) return null;

  return (
    <div className="mt-1">
      <span role="alert" className="text-sm text-error">
        {field.message?.toString()}
      </span>
    </div>
  );
};

export default ErrorMessage;
