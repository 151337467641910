import Link from 'next/link';
import { parseCookies, setCookie } from 'nookies';
import { useEffect, useState } from 'react';

const ACCEPT_COOKIES_CONSENT_KEY = 'accept-cookie';
const ACCEPT_COOKIES_CONSENT_VALUE = '1';

const CookiesConsent = () => {
  const [isAccept, setIsAccept] = useState(true);

  const handleAccept = () => {
    setCookie(null, ACCEPT_COOKIES_CONSENT_KEY, ACCEPT_COOKIES_CONSENT_VALUE);
    setIsAccept(true);
  };

  useEffect(() => {
    const cookies = parseCookies();
    setIsAccept(cookies[ACCEPT_COOKIES_CONSENT_KEY] == '1');
  }, []);

  if (isAccept) {
    return null;
  }

  return (
    <div className="fixed bottom-4 right-1 lg:right-4 px-5 py-2.5 bg-white rounded">
      <div className="flex items-center gap-5 max-w-[665px]">
        <i className="fi fi-rr-cookie h-9 w-9 text-4xl text-primary" />
        <p className="text-sm">
          เราใช้คุกกี้เพื่อพัฒนาประสิทธิภาพ
          และประสบการณ์ที่ดีในการใช้เว็บไซต์ของคุณ
          คุณสามารถศึกษารายละเอียดได้ที่{' '}
          <Link href="/cookies-policy">
            <a className="underline text-primary">คลิกเพื่อดูข้อมูลเพิ่มเติม</a>
          </Link>
        </p>
        <button className="btn btn-primary" onClick={handleAccept}>
          ยอมรับ
        </button>
      </div>
    </div>
  );
};

export default CookiesConsent;
