import { ReactNode } from 'react';

const PartnerLogoWrapper = ({ element }: { element: ReactNode }) => {
  return (
    <div className="flex items-end space-x-2">
      {element}
      <span className="text-xs text-base-300">
        ดำเนินการโดย บริษัทจัดหางาน เพอร์เฟค ฮันเตอร์ จำกัด
      </span>
    </div>
  );
};

export default PartnerLogoWrapper;
