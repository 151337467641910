import phrLogo from '@/public/images/logos/phr-logo.png';
import clsx from 'clsx';
import Image from 'next/image';
import Link from 'next/link';

interface ApplicationLogoProps {
  className?: string;
}
const ApplicationLogo = ({ className }: ApplicationLogoProps) => {
  return (
    <Link href="/">
      <a className={clsx('block', className)}>
        <Image src={phrLogo} alt="phr-logo" layout="responsive" />
      </a>
    </Link>
  );
};

export default ApplicationLogo;
